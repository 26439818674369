<template>
  <div :class="['search-flight', {'search-on-page': searchOnPage}]">
    <div class="search-form-wrap">
      <div class="search-form">

        <FlightsSearchFilters v-if="desktop || searchOnPage"/>
        <div class="search-form-row">
          <SuggestionsFlights/>
          <SearchFormDates/>
          <div class="search-form-col">
            <v-btn depressed block x-large color="primary" class="search-button" @click="searchProducts">Search</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuggestionsFlights  from '@/components/SearchFormComponents/Suggestions/SuggestionsFlights/SuggestionsFlights'
import SearchFormDates  from '@/components/SearchFormComponents/SearchFormDates/SearchFormFligthsDates'
import SearchFormVisitors  from '@/components/SearchFormComponents/SearchFormVisitors/SearchFormVisitors'
import SearchFormSeats  from '@/components/SearchFormComponents/SearchFormSeats/SearchFormSeats'
import FlightsSearchFilters from '@/components/Flights/FlightsSearchFilters/FlightsSearchFilters'

export default {
  props: {
    searchOnPage: {
      default: false
    }
  },
  components: {
    SuggestionsFlights,
    SearchFormDates,
    SearchFormSeats,
    FlightsSearchFilters
  },
  methods: {
    searchProducts() {
      if (this.destinations.length) {
        const from = this.destinations[0].from.code
        const to = this.destinations[0].to.code

        let queryParams = {
          start: this.selectedDates[0],
          class: this.selectedSeatsClass,
          adult: this.passengers.adult,
          trip_type: this.selectedTripType
        }
        if (this.selectedTripType === 'roundtrip') {
          queryParams.end = this.selectedDates[1]
        }
        if (this.childAges.length) {
          let infCounter = 0
          let chCounter = 0

          this.childAges.forEach(age => {
            if (age <= 1) infCounter++
            if (age < 12 && age > 1) chCounter++
          })

          if (chCounter) queryParams.child = chCounter
          if (infCounter) queryParams.infant = infCounter
        }

        queryParams.baggage_included = this.isBaggageIncluded || undefined
        if (!this.searchOnPage) this.$router.push({path: `/flights/${from}${to}`, query: queryParams})
        else {
          this.$router.push({path: `/flights/${from}${to}`, query: queryParams})
          this.$emit('searchFlights')
        }
      }

    },
  },
  computed: {
    destinations() {
      return this.$store.state.flightDestinations
    },
    selectedTripType() {
      return this.$store.state.tripType
    },
    selectedSeatsClass() {
      return this.$store.state.seatsClass
    },
    passengers() {
      return this.$store.state.visitors
    },
    selectedDates() {
      return this.$store.state.selectedDates
    },
    childAges() {
      return this.$store.state.childAges
    },
    isBaggageIncluded() {
      return this.$store.state.isFlightsBaggageIncluded
    },
    desktop() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl
    }
  },
}

</script>

<style lang="scss" src="./search-flights.scss"></style>
