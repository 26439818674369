<template>
  <div class="search-form-row search-form-filters mb-4">
    <SearchFormFilterDropdown
      class="search-form-filters-item"
      :options="tripTypes"
      :pre-selected-option="selectedTripType"
      @select="selectTripType"/>
    <SearchFormFlightsVisitorsBaggage class="search-form-filters-item"/>
    <SearchFormFilterDropdown
      class="search-form-filters-item cabin-class"
      :options="cabinClasses"
      :pre-selected-option="selectedCabinClass"
      @select="selectCabinClass"/>
  </div>
</template>

<script>
import SearchFormFilterDropdown from '@/components/SearchFormComponents/SearchFormFilterDropdown/SearchFormFilterDropdown'
import SearchFormFlightsVisitorsBaggage from '@/components/SearchFormComponents/SearchFormFlightsVisitorsBaggage/SearchFormFlightsVisitorsBaggage'

export default {
  data() {
    return {
      tripTypes: [
        {
          value: 'roundtrip',
          text: 'Roundtrip'
        },
        {
          value: 'one_way',
          text: 'One Way'
        }
      ],
      cabinClasses: [
        {
          value: 'economy',
          text: 'Economy'
        },
        {
          value: 'premium_economy',
          text: 'Premium economy'
        },
        {
          value: 'business',
          text: 'Business'
        },
        {
          value: 'first',
          text: 'First'
        },
      ],
      selectedTripType: {
        value: 'roundtrip',
        text: 'Roundtrip'
      },
      selectedCabinClass: {
        value: 'economy',
        text: 'Economy'
      }
    }
  },
  methods: {
    selectTripType(type) {
      this.selectedTripType = type
      this.$store.commit('SET_TRIP_TYPE', this.selectedTripType.value)
    },
    selectCabinClass(c_class) {
      this.selectedCabinClass = c_class
      this.$store.commit('SET_SEATS_CLASS', this.selectedCabinClass.value)
    },
  },
  components: {
    SearchFormFilterDropdown,
    SearchFormFlightsVisitorsBaggage,
  }
}
</script>
<style lang="scss" src="./flights-search-filters.scss"></style>
